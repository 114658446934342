/* You can add global styles to this file, and also import other style files */

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

/* Custom */

:root {
  --primary-accent-purple: #320072;
}


body {
  background: var(--surface-ground) !important;
}

.content-container {
  max-width: 1500px;
  margin: 0 auto;
}


.gradient-card {
  border: 1px solid rgba(255,255,255,.3) !important;
  background-color: transparent;
  background-image: linear-gradient(132deg, var(--primary-accent-purple), rgba(37, 3, 80, .36));
  color: var(--white);
  border-radius: var(--border-radius);
}


.translucent-wrapper {
  background-image: linear-gradient(140deg, var(--primary-accent-purple) 22%, rgba(50, 0, 114, .2) 98%);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border-radius: 1rem;
}

.text-ground {
  color: var(--surface-ground) !important;
}
